import React from 'react';
import '../../styles/entreprises.css';
import {  Link } from 'react-router-dom';

const Amoa = () => {
  return (
    <div>
      <h2 className='h2_centre'>Assistance Maître ouvrage de Projet (AMOA)</h2>

      <div className='entreprise_bloc2'>
        <div className='img_illust'>

        </div>
        <div className='contenu_service'>
          <h3 className='title-consulting'>Description et livrables du produit: </h3>
          <p>
            Cette assistance en gestion de projet de ATCC consiste à aider à l’élaboration du Kick off de lancement. L’élaboration d’un plan de management de projet, assiste le maître d’ouvrage dans l’élaboration de ces documents de projet. L’AMOA participe aux réunions de projet en y apportant son appui en cas de besoin.
            L’AMOA assure le reporting du projet en donnant mensuellement l’état d’avancement du projet.  Des recommandations et des conseils en cas de besoin aux différents prestataires.
          </p>
          <h3 className='title-consulting'>Livrables attendus: </h3>
          <ul>
            <li>Kick off de Lancement</li>
            <li>Charte de projet</li>
            <li>Registre des parties prenantes du projet</li>
            <li>Plan de gestion des communications</li>
            <li>Cartographie des risques</li>
            <li>Enoncé du contenu du projet</li>
            <li>Structure de découpage du projet</li>
            <li>Etablissement des critères d’acceptation</li>
            <li>Etablissement des taux de réalisation</li>
            <li>Analyses des processus</li>
            <li>Peut participer aux Tests ou aux inspections en cas de besoin.</li>
          </ul>
          <h3 className='title-consulting'>Durée du contrat :</h3>
          <p>6 mois à un an renouvelable selon la durée du projet</p>

          <h3>Forfait mensuel :</h3>
          <p>2 millions de francs / mois</p>

          <h3 className='title-consulting'>Ressources allouées à la mission :</h3>
          <p>2 Experts Seniors</p>
          <Link className="button" to='/contact'>En Savoir Plus</Link>
        </div>
      </div>
    </div>
  );
}

export default Amoa;
