import logo1 from '../images/Partenaires/solibra.png'
import logo2 from '../images/Partenaires/canalplus.png'
import logo3 from '../images/boa.png'
import logo4 from '../images/Partenaires/douane.jpeg'
import logo5 from '../images/Partenaires/rti.jpeg'
import logo6 from '../images/Partenaires/smartenergie.jpeg'


export const partners =
[
    { name: "Solibra", logo: logo1},
    { name: "canalplus", logo: logo2 },
    { name: "boa", logo: logo3 },
    { name: "douaneivoirienne", logo: logo4 },
    { name: "rti", logo: logo5 },
    { name: "smartenergie", logo: logo6 }
];
