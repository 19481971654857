import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import persoInvestissement from '../../images/persoInvestissement.jpg'
import img from '../../images/Autres/non-certifiant.jpg'
import '../../styles/entreprises.css';
import nonCertifiant from "../../images/apropos4.jpg";


const Retraite = () => {

   const navigate = useNavigate()
    return (
        <div>

        <div className="banner-container">
          <img
            src={img}
            alt="Formation non certifiante"
            className="banner-image"
          />
          <div className="banner-title">
            <p className='text-banner'>Formation non certifiante</p>
          </div>
        </div>

        <h2 className='h2_centre'>Formations non certifiantes avec attestations de formation </h2>

        <div className='entreprise_bloc2'>
          <div className="img_illust">
            <img src={nonCertifiant} alt="imageservice1"></img>
          </div>
          <div className='contenu_service'>
            <h3>Ce que vous devez savoir: </h3>
            <p>
              Les formations non certifiantes concernent la gestion de projet,
              la gestion des changements, la gestion des risques, la gouvernance des projets et programmes,
              et la sensibilation à l'Agilité
            </p>
            <p>N.B : Guide PMBOK 6è & 7è Edition offerts en version numérique</p>
          </div>
        </div>

        <div className='entreprise_bloc2'>
          <div className='img_illust'>
            <img src={persoInvestissement} alt='imageservice 2'></img>
          </div>
          <div className='contenu_service'>
            <h3>Pourquoi vous devez faire cette formation</h3>
            <p>
              Est éligible, toute personne désireuse d'apprendre à conduire un projet, le planifier, définir des objectifs et les atteindre,
              de mener les nouveaux projets à la réussite, la conduite du changement, l’accompagnement des transformations,
              d’être sensibilisée et outillée pour assurer une meilleure gestion des risques en projet.
              Toute personne désireuse d’être sensibilisée et outillée pour assurer une meilleure gouvernance des projets et programmes,
              pour assurer une bonne gestion des projets agiles.
            </p>

            <a className="button" to="/contact">Contactez-nous</a>            </div>
        </div>

        </div>
    );
}

export default Retraite;
