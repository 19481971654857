export const menus2 = [

    {href:'#', to :'services/particuliers', service:'Certification PMP'},
    {href:'#', to :'services/entreprises', service:'Certification CAPM'},
  { href: '#', to: 'services/finance', service: 'Certification PSM' },
  { href: '#', to: 'services/assurance', service: 'Certification PSPO I' },
  { href: '#', to: 'services/sante', service: 'Certification PMI-RMP' },
    {href:'#', to :'services/retraite', service:'Formation non Certifiantes'},



    //  {
    //     autres :
    //     [
    //         {href:'#', to :'services/retraite', service:'RETRAITE'},
    //         {href:'#', to :'services/sante', service:'SANTE'},
    //         {href:'#', to :'services/assurance', service:'ASSURANCE'},
    //         {href:'#', to :'services/finance', service:'FINANCE'}
    //     ]
    //  }
   ]
