import React from "react";
import "../styles/Apropos.css";
import Card from "react-bootstrap/Card";
import iconeEtudiant from "../images/cible-etudiant-icon.png";
import iconeCadre from "../images/cible-chef-icon.png";
import iconeEntreprise from "../images/cible-entreprise-icon.png";

function Cibles() {
  return (
    <div>
      <div className="titre_cibles">
        <h2>Nos cibles</h2>
      </div>
      <div className="bloc_cadre_cibles">
        <Card
          data-aos="fade-up"
          data-aos-duration="900"
          style={{
            width: "25rem",
            border: "1px solid #c70d10",
            margin: "10px 0",
            backgroundColor: "transparent",
          }}
        >
          <Card.Body className="cadre_bloc">
            <img
              className="icone-cible"
              src={iconeCadre}
              alt="Icone de particulier"
            />
            <h4>CADRES D'AFRIQUE FRANCOPHONE</h4>
            <p>
              Des prestations sur-mesure pour les particuliers. Platinium
              Conseils répond aux besoins de tous les profils.
            </p>
          </Card.Body>
        </Card>
        <Card
          data-aos="fade-down"
          data-aos-duration="1500"
          style={{
            width: "25rem",
            border: "1px solid #c70d10",
            margin: "10px 0",
            backgroundColor: "transparent",
          }}
        >
          <Card.Body className="cadre_bloc">
            <img
              className="icone-cible"
              src={iconeEntreprise}
              alt="Icone de particulier"
            />
            <h4>ENTREPRISES</h4>
            <p>
              Que vous soyez une entreprise du secteur public ou privé, vous
              êtes le bienvenu chez Platinium Conseils.
            </p>
          </Card.Body>
        </Card>
        <Card
          data-aos="fade-up"
          data-aos-duration="900"
          style={{
            width: "25rem",
            border: "1px solid #c70d10",
            margin: "10px 0",
            backgroundColor: "transparent",
          }}
        >
          <Card.Body className="cadre_bloc">
            <img
              className="icone-cible"
              src={iconeEtudiant}
              alt="Icone de particulier"
            />
            <h4>ETUDIANTS</h4>
            <p>
              La question du coût n'est plus un frein pour les opérateurs du
              secteur informel, vous êtes le bienvenu chez Platinium Conseils.
            </p>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Cibles;
