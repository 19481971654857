import React from "react";
import logo from "../images/LOGO-ATCC.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faLocation,
  faPaperPlane,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import "../styles/NewFooter.css";

function NewFooter() {
  return (
    <div style={{ marginTop: "1rem" }}>
      <footer className="footer-globale">
        <div className="footer-container">
          <div className="row">
            <div className="col" style={{ marginTop: "2.5rem" }}>
              <img
                className="footer-logo"
                src={logo}
                alt="logoatcc"
                width="150px"
                height="120px"
              />
              <div className="footer-social">
                <a href="https://www.facebook.com/profile.php?id=100083342740999">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="https://www.instagram.com/contact_atcc/">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>

                <a href="https://www.linkedin.com/company/african-training-center-consulting/?viewAsMember=true">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </div>
            {/*
            <div className="col">
              <h3>Formations</h3>
              <div className="links">
                <a href="/services/particuliers">Certification PMP</a>
                <a href="/services/entreprises">Certification CAPM</a>
                <a href="/services/finance">Certification PSM I</a>
                <a href="/services/assurance">Certification PSPO I</a>
                <a href="/services/sante">Certification PMI-RMP</a>
                <a href="/services/retraite">Fomations non certifiantes</a>
              </div>
            </div>*/}
            <div className="col" id="useful-links">
              <h3>Liens</h3>
              <div className="links">
                <a href="/apropos">A propos</a>
                <a href="/formation">Formations</a>
                <a href="/consulting">Consulting</a>
                <a href="/contact">Contact</a>
                <a href="#">Faq</a>
              </div>
            </div>
            <div className="col" id="contact">
              <h3>Contact</h3>
              <div className="contact-details">
                <a href="">
                  <FontAwesomeIcon icon={faLocation} />
                </a>

                <p>
                  Cocody Angré, 7ième tranche
                  Abidjan, CÔTE D'IVOIRE
                </p>
              </div>
              <div className="contact-details">
                <a href="">
                  <FontAwesomeIcon icon={faPhone} />
                </a>
                <p className="num">+225 07 04 85 24 43</p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderTop: "2px solid red",
            marginTop: "2rem",
            display: "flex",
            justifyContent: "center",
            paddingTop: "1rem",
          }}
        >
          <span>
            2023 © Tous droit réservés – Designed by MS{" "}
            <span className="codeur">
              <a href="#">KBAVdev</a>{" "}
            </span>
          </span>
        </div>
      </footer>
    </div>
  );
}

export default NewFooter;
