import React from "react";
import "../../styles/services.css";
import { cartService } from "../../datas/ServicesDatas";
import "../../styles/footer.css";
import "../../styles/contact.css";
import { useNavigate, Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Aos from "aos";

const Services = ({ cover, href, title, contenu }) => {
  const navigate = useNavigate();

  Aos.init({
    duration: 900,
    easing: "ease-in-out",
  });

  return (
    <>
      <div className="section-services">
        <h1 data-aos="fade-up">NOS FORMATIONS</h1>

        <div className="cadre-services">
          {cartService &&
            cartService.map((item, index) => (
              <Card
                className="service-card"
                data-aos="flip-left"
                key={index}
                style={{ width: "22rem", margin: "10px 0px" }}
              >
                <Card.Img variant="top" src={item.cover} />
                <Card.Body className="service-body">
                  <Card.Title className="card_title">{item.title}</Card.Title>
                  <Card.Text className="card-text">{item.contenu}</Card.Text>
                </Card.Body>
                <a className="button" href="/contact">Contactez-nous</a>
              </Card>
            ))}
        </div>
      </div>

      {/* <h1 style={{textAlign:'center'}}>Nos Offres</h1>
        <div className='all_cards'>
            {cartService && cartService.map((item, key)=> (
                <div className='cadre_card'>
                    <div key={key} style={{backgroundImage:`url(${item.cover})`, backgroundRepeat:'no-repeat'}} className ='img_bg'>
                        <div className = 'card_sup'>
                        <h2>
                            <a href={item.href}>{item.title}</a>
                        </h2>
                        <p>{item.contenu}</p>
                        <div className='card_bouton'>
                            <Button
                            size="sm"
                            onClick={()=>navigate(item.to)}
                            >
                             En savoir plus
                            </Button>{' '}
                        </div>
                      </div>
                   </div>
                </div>
                )) }
        </div> */}
    </>
  );
};
export default Services;
