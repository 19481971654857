import React from 'react'
import Button from 'react-bootstrap/Button';
import '../../styles/entreprises.css'
import { Link, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import persoInvestissement from '../../images/persoInvestissement.jpg'
import ServiceBanner from '../ServiceBanner';
import pmiRmpImage from '../../images/pmi-2.png';
import img from '../../images/Autres/pmi.jpg'


function Sante() {
   const navigate = useNavigate()

   const handleRedirection = () => {
      // Rediriger l'utilisateur vers une ancre
      // navigate('/contact/#contactez-nous');
      window.location.href = "/contact/#contactez-nous";
    };

  return (
    <div>

      <div className="banner-container">
        <img
          src={img}
          alt="Formation certifiante"
          className="banner-image"
        />
        <div className="banner-title">
          <p className='text-banner'>Formation certifiante</p>
        </div>
      </div>

      <h2 className='h2_centre'>PMI – RMP ® Project management institut – Risque Management Professional</h2>

      <div className='entreprise_bloc2'>
        <div className='img_illust'>
          <img src={pmiRmpImage} alt='imageservice1'></img>
        </div>
        <div className='contenu_service'>
          <h3>Ce que vous devez savoir: </h3>
          <p>
            Les risques présentent des défis. Non gérés, ils peuvent entraîner des retards, des dépenses, des objectifs manqués et même l'échec du projet. Mais bien gérés, ils peuvent offrir un avantage concurrentiel et même des opportunités. La gestion des risques est un aspect essentiel de toutes les activités organisationnelles. Validez vos compétences avec la certification PMI-RMP et devenez le spécialiste des risques dont votre organisation a besoin.
            La certification PMI-RMP est décernée par le PMI (Project management institute).
          </p>
          <p>N.B: Guide PMBOK 6è & 7è Edition et le The Standard for Risk Management in Portfolios, Programs, and Projects offerts en version numérique</p>
        </div>
      </div>

      <div className='entreprise_bloc2'>

        <div className='contenu_service'>
          <h3>Pourquoi vous devez faire cette formation</h3>
          <p>
            La certification PMI-RMP vous positionne pour :
            <ul>
              <li>
            Identifier les problèmes avant qu'ils ne surviennent.
              </li>
              <li>
            Évaluer les risques du projet.
              </li>
              <li>
            Atténuer les menaces.
              </li>
              <li>
            Maximiser les résultats et respecter les délais.
              </li>
              <li>
            Exploiter, et même créer des opportunités.
              </li>
              <li>
              Économiser des ressources pour votre projet et votre organisation.
              </li>
              </ul>

            Toute personne possédant au moins 2 années d'expériences professionnelles de préférence dans la gestion des risques de projet avec un diplôme universitaire.
          </p>
          <p className='prix-formation'>
            Prix de la formation (HT): 950.000 F CFA
          </p>
          <p className='frais-examen'>Frais d’examen (HT) : 450.000 F CFA</p>
          <a className="button" href="/contact">Contactez-nous</a>         </div>
      </div>



    </div>
  )
}

export default Sante
