import React from "react";
import "./styles/App.css";
import { Routes, Route } from "react-router-dom";
import Acceuil from "./composants/Acceuil";
import Apropos from "./composants/Apropos";
import Services from "./composants/services/Services";
import Contacts from "./composants/Contacts";
import Particulier from "./composants/services/Particulier";
import Entreprises from "./composants/services/Entreprises";
import Retraite from "./composants/services/Retraite";
import Sante from "./composants/services/Sante";
import Footer from "./composants/Footer";
import Assurances from "./composants/services/Assurances";
import Finances from "./composants/services/Finances";
import Topbar from "./composants/Topbar";
import NavNav from "../src/composants/NavVav";
import NewFooter from "./composants/NewFooter";
import Navig from "../src/composants/Navig";
import { menus } from "../src/Exports/Menus";
import { menus2 } from "../src/Exports/Menus2";
import { menus3 } from "../src/Exports/Menus3";
import { MenuConsulting } from "./Exports/MenuConsulting";
import Amoa from "./composants/Consulting/Amoa";
import AuditInterneExterne from "./composants/Consulting/AuditInterneExterne";
import Roadmap from "./composants/Consulting/Roadmap";
import PolitiqueGestionProjet from "./composants/Consulting/PolitiqueGestionProjet";
import RisquesCartographie from "./composants/Consulting/RisquesCartographie";
import PmoSoutien from "./composants/Consulting/PmoSoutien";
import PmoConformite from "./composants/Consulting/PmoConformite";
import Consulting from "./composants/Consulting/Consulting";
import Formation from "./composants/services/Formation";

function App() {
  return (
    <>

      <Topbar />
      <Navig
        title="ATCC"
        bg="ligth"
        expand="lg"
        menus={menus}
        titre="Services"
        menus2={menus2}
        menus3={menus3}
        MenuConsulting={MenuConsulting}
      />
      <Routes>
        <Route path="/" element={<Acceuil />} />
        <Route path="apropos" element={<Apropos />} />
        <Route path="contact" element={<Contacts />} />
        <Route path="consulting" element={<Consulting />} />
        <Route path="formation" element={<Formation />} />
        <Route path="services" element={<Services />} />
        <Route path="services/particuliers" element={<Particulier />} />
        <Route path="services/entreprises" element={<Entreprises />} />
        <Route path="services/retraite" element={<Retraite />} />
        <Route path="services/sante" element={<Sante />} />
        <Route path="services/assurance" element={<Assurances />} />
        <Route path="services/finance" element={<Finances />} />
        <Route path="consulting/amoa" element={<Amoa />} />
        <Route path="consulting/audit" element={<AuditInterneExterne />} />
        <Route path="consulting/roadmap" element={<Roadmap />} />
        <Route path="consulting/gestion" element={<PolitiqueGestionProjet />} />
        <Route path="consulting/risque" element={<RisquesCartographie />} />
        <Route path="consulting/pmosoutien" element={<PmoSoutien />} />
        <Route path="consulting/pmoconformite" element={<PmoConformite />} />
      </Routes>



      {/* <Contacts/> */}
      <NewFooter />
    </>
  );
}

export default App;

//<Navig title="PlatiniumConseils" bg="ligth" expand="lg" menus={menus} titre="Services" menus2={menus2} menus3={menus3} />
//<NewNavbar menus={menus} titre="Services" menus2={menus2} menus3={menus3} />
//<Footer />
