import React from "react";
import img from "../images/Autres/acceuilApropos.jpg";
import "../styles/About.css";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className="container">
      <h1 className='title'>A Propos de nous</h1>
      <div className='about-content'>
      <div className='image'>
    <img src={img} alt="" />
      </div>
      <div className='content'>
          <p>African Training Center & Consulting (ATCC) est un cabinet de conseil et d’accompagnement spécialisé
          en management de projet et répond à l'ensemble des besoins en formation, conseil, accompagnement,
          système d’information projet et outils de planification en vue d'accroître la performance des entreprises. </p>
          <a className="button" href='/apropos'>En Savoir Plus</a>
      </div>
      </div>


        </div>


  );
}

export default About;
