import React from 'react'
import { Link } from 'react-router-dom';
import '../../styles/entreprises.css'
import persoInvestissement from '../../images/persoInvestissement.jpg'
import psmIImage from '../../images/psm-2.png';
import img from '../../images/Autres/psm.jpg'
function Finances() {
  return (
    <div>

      <div className="banner-container">
        <img
          src={img}
          alt="Formation certifiante"
          className="banner-image"
        />
        <div className="banner-title">
          <p className='text-banner'>Formation certifiante</p>
        </div>
      </div>

      <h2 className='h2_centre'>PSM I Professional Scrum Master I </h2>

      <div className='entreprise_bloc2'>
        <div className='img_illust'>
          <img src={psmIImage} alt='imageservice1'></img>
        </div>
        <div className='contenu_service'>
          <h3>Ce que vous devez savoir: </h3>
          <p>
            La certification Professional Scrum Master (PSM I) valide votre connaissance du cadre Scrum, des responsabilités du Scrum Master et de la manière d'appliquer Scrum.
            PSM I est reconnu par l'industrie comme une certification qui démontre un niveau fondamental de maîtrise de Scrum. En tant que titulaire de la certification PSM I, vous avez prouvé que vous comprenez Scrum tel que décrit dans le Guide Scrum et comment appliquer Scrum dans les équipes Scrum. Les titulaires de PSM I ont une terminologie et une approche cohérentes de Scrum.
            La certification PSM I est décernée par Scrum.Org (la maison du Scrum). Scrum.org, la maison de Scrum, a été fondée en 2009 par le co-créateur de Scrum Ken Schwaber en tant qu'organisation basée sur une mission pour aider les personnes et les équipes à résoudre des problèmes complexes. Ils le font en permettant aux gens d'appliquer Professional Scrum par le biais de formations, de certifications et d'apprentissages continus, tous basés sur un modèle de compétences commun.
          </p>
          <p>N.B : Guide Scrum offert en version numérique</p>
        </div>
      </div>

      <div className='entreprise_bloc2'>

        <div className='contenu_service'>
          <h3>Pourquoi vous devez faire cette formation</h3>
          <p>
            La certification PSM I aide à acquérir une meilleure compréhension de l'approche agile et de ses pratiques.
            D'ailleurs, cela permet aux professionnels de mieux appliquer la méthode dans leur travail. Par conséquent, ils peuvent aider leur équipe à être plus productive.
            Il n’y a pas de prérequis pour passer la certification PSM I. Toute personne guidée par la passion du Scrum peut passer cette certification.
          </p>
          <p className='prix-formation'>
            Prix de la formation (HT): 500.000 F CFA
          </p>
          <p className='frais-examen'>Frais d’examen (HT) : 150.000 F CFA</p>
          <a className="button" href="/contact">Contactez-nous</a>
        </div>
      </div>
    </div>
  )
}

export default Finances
