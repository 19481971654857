import React from 'react';
import '../../styles/entreprises.css';
import { Link } from 'react-router-dom';

const AuditInterneExterne = () => {
  return (
    <div>
      <h2 className='h2_centre'>Audit interne ou externe de projet</h2>

      <div className='entreprise_bloc2'>
        <div className='img_illust'>

        </div>
        <div className='contenu_service'>
          <h3 className='title-consulting'>Description et livrables du produit: </h3>
          <ul>
            <li>-	Analyse SWOT</li>
            <li>-	Analyse des processus</li>
            <li>-	Analyse de la gouvernance de projet</li>
            <li>-	Détection des obstacles </li>
            <li>-	Analyse WHY WHY</li>
            <li>-	Analyse des documents projets</li>
            <li>-	Etats du projet </li>
            <li>-	Recommandations</li>

          </ul>

          <h3 className='title-consulting'>Livrables attendus: </h3>
          <ul>
            <li>-	Résultat de l’audit et recommandations</li>
          </ul>
          <h3 className='title-consulting'>Durée de la mission :</h3>
          <p>-	40 H – 5 JOURS</p>

          <h3>Prix jour - homme :</h3>
          <p>500000 frs/ jour  </p>

          <h3 className='title-consulting'>Ressources allouées à la mission :</h3>
          <p>1 Expert Senior
            NB : Les déplacements sont à la charge du client
</p>
          <Link className="button" to='/contact'>En Savoir Plus</Link>
        </div>
      </div>
    </div>
  );
}

export default AuditInterneExterne;
