import React from 'react';
import '../../styles/entreprises.css';
import { Link } from 'react-router-dom';
const Roadmap = () => {
  return (
    <div>
      <h2 className='h2_centre'>Project ROADMAP ou feuille de route pour les projets AGILES ou hybrides </h2>

      <div className='entreprise_bloc2'>
        <div className='img_illust'>

        </div>
        <div className='contenu_service'>
          <h3 className='title-consulting'>Description et livrables du produit: </h3>
          <p>
            La difficulté dans les projets Agiles ou hybrides est de définir une feuille de route permettant de pouvoir coordonner le projet, organiser les différentes étapes tout en restant transparent. ATCC vous aidera à définir une feuille de route de votre projet, organiser des points de collecte des feedbacks clients, ainsi que de donner la prédominance aux tests fonctionnels et non fonctionnels.
          </p>
          <h3 className='title-consulting'>Livrables attendus: </h3>
          <ul>
            <li>-	PROJECT ROADMAP</li>
            <li>-	Planification des réunions clients prestataires</li>
            <li>-	Établissement des DEFINITION OF DONE (DoD) des livrables </li>
          </ul>
          <h3 className='title-consulting'>Durée de la mission:</h3>
          <p>-	40 H équivalent à 5 JOURS</p>

          <h3>Prix jour - homme:</h3>
          <p>500000 frs/ jour  </p>

          <h3 className='title-consulting'>Ressources allouées à la mission :</h3>
          <p>1 Expert Senior</p>
          <Link className="button" to='/contact'>En Savoir Plus</Link>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
