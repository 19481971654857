import React from 'react';
import img from '../images/Autres/valeuretobjectif.jpg';
import '../styles/objectif.css';


function Objectif() {
  return (
    <div className="container-objectif">
      <h1 className='title'>Nos valeurs et objectifs</h1>
      <div className='about-content'>
        <div className='image'>
          <img src={img} alt="" />
        </div>
        <div className='content'>
          <p>Avec passion, responsabilité mais surtout une culture d’entreprise vivante,
            nous apportons un accompagnement efficace, une véritable valeur ajoutée
            et assurons la bonne gouvernance de vos projets. </p>
          <a className="button" href="/apropos">En Savoir Plus</a>
        </div>
      </div>

    </div>
  );
}

export default Objectif;
