import React from 'react';
import Button from 'react-bootstrap/Button';
import '../../styles/entreprises.css'
import capmImage from '../../images/capm-2.png';
import img from '../../images/Autres/capm.jpg'





const Entreprises = () => {
    return (
        <>

        <div className="banner-container">
          <img
            src={img}
            alt="Formation certifiante"
            className="banner-image"
          />
          <div className="banner-title">
            <p className='text-banner'>Formation certifiante</p>
          </div>
        </div>

        <h2 className='h2_centre'>CAPM Certified Associated in Project Management </h2>

        <div className='entreprise_bloc2'>
          <div className='img_illust'>
            <img src={capmImage} alt='imageservice1'></img>
          </div>
          <div className='contenu_service'>
            <h3>Ce que vous devez savoir: </h3>
            <p>
              Cette certification indique que la personne possède les connaissances des principes et de la terminologie du Guide du référentiel de connaissances en gestion de projet (Guide PMBOK®), qui décrit les bonnes pratiques généralement reconnues en matière de gestion de projet.
              La certification CAPM®est décernée par le PMI (Project management institute).
            </p>
            <p>N.B : Guide PMBOK 6è & 7è Edition offerts en version numérique</p>
          </div>
        </div>

        <div className='entreprise_bloc2'>

          <div className='contenu_service'>
            <h3>Pourquoi vous devez faire cette formation</h3>
            <p>
              Les personnes qui portent le titre CAPM® après leur nom bénéficient
              d’un haut niveau de crédibilité de la part des chefs de projet ayant
              la qualification PMP®, ainsi que des employeurs et des pairs.
              Sont éligibles,les personnes détentrices du BAC n’ayant pas d’expérience
              professionnelle dans la gestion de projet mais qui sont intéressées par
              la gestion de projet peuvent bénéficier de cette certification. Les personnes
              qui apportent des compétences spécialisées à une équipe de projet peuvent également
              bénéficier de cette certification en leur permettant d’aligner leur travail sur celui des chefs de projet.
            </p>
            <p className='prix-formation'>
              Prix de la formation (HT): 750.000 F CFA
            </p>
            <p className='frais-examen'>Frais d’examen (HT) : 250.000 F CFA</p>
            <a className="button" href="/contact">Contactez-nous</a>
          </div>
        </div>

        </>
    );
}

export default Entreprises;
