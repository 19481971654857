import React from "react";
import "../styles/ServiceBanner.css";
// import img from '../images/formation-banière.jpg';
import image from "../images/formation-banière.jpg";
function ServiceBanner() {
  return (
    <div className="banner-container">
      <img src={image} alt="Formation certifiante" className="banner-image" />
      <div className="banner-title">Formation certifiante</div>
    </div>
  );
}

export default ServiceBanner;
