import React from 'react';
import '../../styles/entreprises.css';
import { Link } from 'react-router-dom';
const PolitiqueGestionProjet = () => {
  return (
    <div>
      <h2 className='h2_centre'>ELABORATION D’UNE POLITIQUE DE GESTION DE PROJET</h2>

      <div className='entreprise_bloc2'>
        <div className='img_illust'>

        </div>
        <div className='contenu_service'>
          <h3 className='title-consulting'>Description et livrables du produit: </h3>
          <p>
            ATCC s’engage à rédiger une politique de gestion de projet pour votre organisation qui s’adaptera à votre réalité tout en respectant la norme PMI en matière de gestion de projet. De plus en plus, l’agilité s’introduit dans nos projets d’où l’importance d’intégrer l’agilité dans votre politique projet ou de mettre votre politique Projet à jour.
          </p>
          <h3 className='title-consulting'>Livrables attendus: </h3>
          <ul>
            <li>	Analyse SWOT des procédures et des mises à jour</li>
            <li>	Rédaction de la politique de gestion de projet</li>
          </ul>
          <h3 className='title-consulting'>Durée du contrat :</h3>
          <p>30 Jours</p>
          <h3>Prix jour - Homme:</h3>
          <p>Expert Senior = 500000 Ffca /Jour</p>
          <p>Expert Junior = 300000 Ffca/Jour  </p>

          <h3 className='title-consulting'>Ressources allouées à la mission :</h3>
          <p>	1 Expert Senior</p>
          <p>	1 Expert Junior</p>
          <h4>NB : Frais de déplacement à la charge du client</h4>
          <Link className="button" to='/contact'>En Savoir Plus</Link>
        </div>
      </div>
    </div>
  );
}

export default PolitiqueGestionProjet;
