import React from "react";
import "../styles/Apropos.css";
import Card from "react-bootstrap/Card";
import img1 from "../images/APROPOS.jpg";
import img2 from "../images/Autres/apropos2.jpg";
import AOS from "aos";
import img3 from "../images/Autres/apropos3.jpg";
import iconeEtudiant from "../images/cible-etudiant-icon.png";
import iconeCadre from "../images/cible-chef-icon.png";
import iconeEntreprise from "../images/cible-entreprise-icon.png";
import { Link } from "react-router-dom";

// import Apropos4 from '../images/apropos4.jpg';
const Apropos = () => {
  AOS.init();
  return (
    <>
      <div className="blocBaseApropos">
        <Card
          className="card_img"
          style={{
            width: "30rem",
            border: "1px solid transparent",
            margin: "10px 0",
            backgroundColor: "transparent",
          }}
        >
          <Card.Body className="bloc_image">
            <Card.Img variant="top" src={img1} alt="image_d illistration" />
          </Card.Body>
        </Card>
        <Card
          style={{
            width: "35rem",
            border: "1px solid transparent",
            margin: "10px 0",
            backgroundColor: "transparent",
          }}
        >
          <Card.Body className="bloc_texte">
            <h3>À PROPOS DE NOUS</h3>
            <p>
              African Training Center & Consulting (ATCC) est un cabinet de
              conseil et d’accompagnement spécialisé en management de projet et
              répond à l'ensemble des besoins en formation, conseil,
              accompagnement, système d’information projet et outils de
              planification en vue d'accroître la performance des entreprises.
              Nous alignons les entreprises aux standards internationaux en
              management de projets pour une meilleure atteinte de leurs
              objectifs stratégiques. Par ailleurs, depuis plus de 15 ans, nos
              experts forment les étudiants et cadres d’Afrique francophone.
              Nous les accompagnons à l’obtention de certifications
              internationales en management de projet et à la mise en place
              d’une structure en conduite de projet (PMO) dans les entreprises.
            </p>
            <p>
              Nous offrons à ceux qui nous font confiance les meilleures
              formations en matière de Management de projet. <br></br>
              <ul>
                <li> Formation en Certification PMP </li>
                <li>Formation en Certification CAPM</li>
                <li> Formation en Certification PSM I</li>
                <li> Formation en Certification PSPO I</li>
                <li> Formation en Certification PMI – RMP</li>
                <li> Des Formations non certifiantes </li>
              </ul>
              {/* l'accompagnement et la recherche de financement, l'accompagenement dans le choix de solutions d'assurance en vie et en non-vie, la santé, la formation et l'inclusion financière. */}
              Notre approche globale s'adresse aussi aux entreprises désireuses
              d'avoir de meilleurs résultats en matière de gestion de projet.
            </p>
            <div>
              <a className="button" href="/contact">Contactez-nous</a>
            </div>
          </Card.Body>
        </Card>
      </div>

      <div className="blocBaseApropos">
        <Card
          className="card_img"
          style={{
            width: "40rem",
            border: "1px solid transparent",
            margin: "10px 0",
            backgroundColor: "transparent",
          }}
        >
          <Card.Body className="bloc_image2">
            <Card.Img
              data-aos="zoom-in-right"
              data-aos-duration="1000"
              style={{ margin: "10px 0" }}
              variant="top"
              src={img2}
              alt="image_d illistration"
            />
          </Card.Body>
        </Card>

        <Card
          style={{
            width: "35rem",
            border: "1px solid transparent",
            margin: "0px 0",
            backgroundColor: "transparent",
          }}
        >
          <Card.Body className="bloc_texte">
            <h3 className="h3_apropos">Notre Mission & Valeur</h3>
            <p>
              Notre mission est d’aligner la capacité professionnelle des Hommes
              ainsi que des organisations aux standards internationaux en
              management de projet. Avec passion, responsabilité mais surtout
              une culture d’entreprise vivante, nous apportons un accompagnement
              efficace, une véritable valeur ajoutée et assurons la bonne
              gouvernance de vos projets.
            </p>
            <div>
              <a className="button" href="/contact">Contactez-nous</a>
            </div>
          </Card.Body>
        </Card>

        <Card
          className="card_img"
          style={{
            width: "40rem",
            border: "1px solid transparent",
            margin: "10px 0",
            backgroundColor: "transparent",
          }}
        >
          <Card.Body className="bloc_image2">
            <Card.Img
              data-aos="zoom-in-left"
              data-aos-duration="1000"
              style={{ margin: "10px 0" }}
              variant="top"
              src={img3}
              alt="image_d illistration"
            />
          </Card.Body>
        </Card>

        <Card
          style={{
            width: "35rem",
            border: "1px solid transparent",
            margin: "0px 0",
            backgroundColor: "transparent",
          }}
        >
          <Card.Body className="bloc_texte">
            <h3 className="h3_apropos">Notre équipe </h3>
            <p>
              Notre équipe est constituée de consultants certifiés
              pluridisciplinaires et est en mesure d’apporter à la fois
              l’expertise technique pointue requise mais également la vision
              stratégique nécessaire à la conduite de vos projets.
            </p>
            <div>
              <a className="button" href="/contact">Contactez-nous</a>
            </div>
          </Card.Body>
        </Card>
      </div>

      <div className="bloc_cibles">
        <div className="titre_cibles">
          <h2>Nos cibles</h2>
        </div>
        <div className="bloc_cadre_cibles">
          <Card
            data-aos="fade-up"
            data-aos-duration="900"
            style={{
              width: "25rem",
              border: "1px solid #c70d10",
              margin: "10px 0",
              backgroundColor: "transparent",
            }}
          >
            <Card.Body className="cadre_bloc">
              <img
                className="icone-cible"
                src={iconeCadre}
                alt="Icone de particulier"
              />
              <h4>CADRES D'AFRIQUE FRANCOPHONE</h4>
              <p>
                Des prestations sur-mesure pour les particuliers. Platinium
                Conseils répond aux besoins de tous les profils.
              </p>
            </Card.Body>
          </Card>
          <Card
            data-aos="fade-down"
            data-aos-duration="1500"
            style={{
              width: "25rem",
              border: "1px solid #c70d10",
              margin: "10px 0",
              backgroundColor: "transparent",
            }}
          >
            <Card.Body className="cadre_bloc">
              <img
                className="icone-cible"
                src={iconeEntreprise}
                alt="Icone de particulier"
              />
              <h4>ENTREPRISES</h4>
              <p>
                Que vous soyez une entreprise du secteur public ou privé, vous
                êtes le bienvenu chez Platinium Conseils.
              </p>
            </Card.Body>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-duration="900"
            style={{
              width: "25rem",
              border: "1px solid #c70d10",
              margin: "10px 0",
              backgroundColor: "transparent",
            }}
          >
            <Card.Body className="cadre_bloc">
              <img
                className="icone-cible"
                src={iconeEtudiant}
                alt="Icone de particulier"
              />
              <h4>ETUDIANTS</h4>
              <p>
                La question du coût n'est plus un frein pour les opérateurs du
                secteur informel, vous êtes le bienvenu chez Platinium Conseils.
              </p>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Apropos;
