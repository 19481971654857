import React from 'react'
import '../../styles/entreprises.css'
import pspoImage from '../../images/pspo-2.png';
import img from '../../images/Autres/pspo.jpg'

function Assurances() {
  return (
    <div>
      <div className="banner-container">
        <img
          src={img}
          alt="Formation certifiante"
          className="banner-image"
        />
        <div className="banner-title">
          <p className='text-banner'>Formation certifiante</p>
        </div>
      </div>

      <h2 className='h2_centre'>PSPO I Professional Scrum Product Owner I </h2>

      <div className='entreprise_bloc2'>
        <div className='img_illust'>
          <img src={pspoImage} alt='imageservice1'></img>
        </div>
        <div className='contenu_service'>
          <h3>Ce que vous devez savoir: </h3>
          <p>
            La certification Professional Scrum Product Owner (PSPO I) valide votre connaissance du cadre Scrum et votre capacité à soutenir et permettre la création et la livraison de valeur. Obtenir le PSPO I montre votre compréhension de la façon de combler le fossé entre la stratégie et la gestion de produit agile afin de créer des produits de valeur à l'aide de Scrum.
            La certification PSPO I est décernée par Scrum.Org (la maison du Scrum).
          </p>
          <p>N.B : Guide Scrum offert en version numérique</p>
        </div>
      </div>

      <div className='entreprise_bloc2'>

        <div className='contenu_service'>
          <h3>Pourquoi vous devez faire cette formation</h3>
          <p>
            La certification PSPO I aide à acquérir une meilleure compréhension
            de l'approche agile et de ses pratiques. D'ailleurs, cela permet aux professionnels
            de mieux appliquer la méthode dans leur travail. Par conséquent, ils peuvent aider
            leur équipe à être plus productive. Il n’y a pas de prérequis pour passer la certification PSPO I. Toute personne guidée par la passion du Scrum peut passer cette certification.
          </p>
          <p className='prix-formation'>
            Prix de la formation (HT): 500.000 F CFA
          </p>
          <p className='frais-examen'>Frais d’examen (HT) : 150.000 F CFA</p>
          <a className="button" href="/contact">Contactez-nous</a>
          </div>
      </div>

    </div>
  )
}

export default Assurances
