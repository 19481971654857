export const MenuConsulting = [

  { href: '#', to: 'consulting/amoa', consulting: 'Assistance maître ouvrage de Projet ' },
  { href: '#', to: 'consulting/audit', consulting: 'Audit interne ou externe de projet' },
  { href: '#', to: 'consulting/roadmap', consulting: 'Project Roadmap ' },
  { href: '#', to: 'consulting/gestion', consulting: "Elaboration d'une politique de gestion de projet" },
  { href: '#', to: 'consulting/risque', consulting: 'Analyse et cartographie des risques' },
  { href: '#', to: 'consulting/pmosoutien', consulting: "Assistance pour la mise en place d'un PMO de soutien" },
  { href: '#', to: 'consulting/pmoconformite', consulting: "Assistance pour la mise en place d'un PMO de conformité" },

]
