import React from 'react';
import '../../styles/entreprises.css';
import { Link } from 'react-router-dom';

const RisquesCartographie = () => {
  return (
    <div>
      <h2 className='h2_centre'>ANALYSE DES RISQUES ET CARTOGRAPHIE DES RISQUES</h2>

      <div className='entreprise_bloc2'>
        <div className='img_illust'>

        </div>
        <div className='contenu_service'>
          <h3 className='title-consulting'>Description et livrables du produit: </h3>
          <ul>
            <li>Analyse des documents</li>
            <li>	Analyse SWOT de l’organisation </li>
            <li>	Interview</li>
            <li>Analyse des processus et procédures</li>
            <li>	Rapport des risques</li>
            <li>Cartographie des risques</li>
          </ul>
          <h3 className='title-consulting'>Livrables attendus: </h3>
          <ul>
            <li>Rapports des risques</li>
            <li>		Cartographie sur les risques </li>
          </ul>
          <h3 className='title-consulting'>Durée de la mission:</h3>
          <p>	80 H – 10 JOURS</p>

          <h3>Prix jour - homme:</h3>
          <p>	Expert Senior = 500000 frs/ jour</p>
          <p>	Expert Junior = 300000 frs/ Jour</p>

          <h3 className='title-consulting'>Ressources allouées à la mission :</h3>
          <p> 1 Expert Senior et 1 expert Junior</p>
          <Link className="button" to='/contact'>En Savoir Plus</Link>
        </div>
      </div>
    </div>
  );
}

export default RisquesCartographie;
