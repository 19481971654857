import React from 'react'
import imgFinance from '../images/finance.jpg'
import imgSante from '../images/Formations/formation1.jpg'
import imgAssurance from '../images/Formations/formation3.jpg'
import imgRetraite from '../images/Formations/formation2.jpg'
import imgEntreprise from '../images/entreprise.jpg'
import imgEntreprise2 from '../images/particulier.jpg'

export const cartService = [
    {
        cover: imgEntreprise2,
    title: ' PMP® Project Management Professional',
    to: '/contact',
        contenu : <p>
          Préparation à la certification PMP®
       <br></br>
       <br></br>
          Le PMP® vous ouvre des portes dans toutes les industries et vous connecte à une communauté d’organisations et d’experts émérites dans le monde entier.
        </p>,
    },
     {

         cover: imgEntreprise ,
       to: '/contact',
       title: 'CAPM® Certified Associated in Project Management ',
         contenu :
         <p>
             Préparation à la certification CAPM®
       <br></br>
       <br></br>
Le titre CAPM® après leur nom bénéficient d’un haut niveau de crédibilité de la part des chefs de projet ayant la qualification PMP®, ainsi que des employeurs et des pairs.        </p>,
     },
     {
         cover: imgFinance,
       to: '/contact',
       title: 'Préparation à la certification PSM I ',
         contenu :
         <p>
             Préparation à la certification PSM
       <br></br>
       <br></br>
La certification PSM I aide à acquérir une meilleure compréhension de l'approche agile et de ses pratiques.        </p>,
     },
     {
         cover: imgAssurance,
       to: '/contact',
       title: 'certification PSPO I Professional Scrum Product Owner I ',
         contenu :
         <p>
             Préparation à la certification PSPO
       <br></br>
       <br></br>
La certification PSPO I aide à acquérir une meilleure compréhension de l'approche agile et de ses pratiques.         </p>,

     },
     {
        cover: imgSante,
       to: '/contact',
       title: 'certification PMI – RMP ',
         contenu :
         <p>
            Préparation à la certification PMI-RMP
       <br></br>
       <br></br>
La certification PMI-RMP vous permet d'identifier les problèmes avant qu'ils ne surviennent, évaluer les risques et atténuer les menaces     </p>,
     },
     {
         cover: imgRetraite,
       to: '/contact',
       title: 'Formations non certifiantes',
         contenu :
         <p>
           Attestations de formation
       <br></br>
       <br></br>
Les formations non certifiantes concernent la gestion de projet, la gestion des changements, la gestion des risques, la gouvernance des projets et programmes, et la sensibilisation à l'Agilité.        </p>,

     },
]
