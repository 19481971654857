import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import { Container, Grid } from "react-bootstrap";
import '../styles/banner.css';
import bannerImage from '../images/accueil-image-deux.jpg'
const Banner = () => {
  return (
    <header className='mediaHeader' style={{ backgroundImage: `url(${bannerImage})`, height:700}}>
      <Container>
        <div className= 'viewportHeader'>
          <div className= 'header'>
            <h1 className="title-banner">Bienvenue sur African Training Center and Consulting <br /></h1>
            <div className= 'banner-actions'>
              <div>
                <a className="banner-bouton" href="/contact">En savoir plus</a>
              </div>
              <div>
                <a className="banner-bouton" href="/contact">Contactez-nous</a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </header >
  );
}

export default Banner;
