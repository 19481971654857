import React from "react";
import "../styles/contact.css";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Button from "react-bootstrap/Button";
import iconeLoca from "../assets/localisation.png";
import iconeEmail from "../assets/email.png";
import iconeTel from "../assets/telephone.png";
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2'

const Contact = () => {
  const form = useRef();

  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [numero, setNumero] = useState("");
  const [email, setEmail] = useState("");
  const [type, setLieu] = useState("");
  const [message, setMessage] = useState("");



  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_n2pmcwn",
        "template_4g3x2jh",
        form.current,
        "N09iOhIRm499SIfIm"
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result);
          Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Votre message a été envoyé avec succès! Vous serez contacté par un coach.',
          });
        },
        (error) => {
          console.error('Error sending email:', error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Erreur lors de l/envoi!',
            footer: ''
          })
        }
      );
    e.target.reset();
    setNom('');
    setPrenom('');
    setEmail('');
    setLieu('');
    setMessage('');
    setNumero('');
  }



  return (
    <>
      <div id="success-message" className="hidden">
        Votre message a été envoyé avec succès! Vous serez contacté par un coach.
      </div>
      <div id="contactez-nous" className="contactAll">
        <div className="topContact">
          <h2>Contactez-nous</h2>
          <ul style={{ textAlign: "center", paddingRight: "1.5rem" }}>
            <li>
              <Link className="lienAccueil" to={"/"}>
                Accueil -
              </Link>
            </li>
            <li>
              <span>Contact</span>
            </li>
          </ul>
        </div>
        <div className="miniParaph">
          <span>Avez-vous des questions ?</span>
        </div>
        <div className="formulaire">
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4252.40668392078!2d-3.9842220048233683!3d5.387337701026045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2sci!4v1699597535984!5m2!1sfr!2sci"
              width="600"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>{" "}
          </div>

          <Form ref={form} onSubmit={sendEmail}>
            <div className=" grids">
              <Grid container spacing={8}>
                <Grid xs={12} md={6}>
                  <TextField
                    id="outlined-basic1"
                    color="error"
                    label="Nom"
                    variant="outlined"
                    className="form-input"
                    style={{
                      borderBottomRightRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                    required
                    type="text"
                    placeholder="Votre nom"
                    name="nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    id="outlined-basic2"
                    color="error"
                    label="Prénoms"
                    variant="outlined"
                    className="form-input"
                    style={{
                      borderBottomRightRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                    required
                    type="text"
                    placeholder="Votre Prénoms"
                    name="prenom"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={8}>
                <Grid xs={12} md={6}>
                  <TextField
                    id="outlined-basic3"
                    color="error"
                    label="Numéro whatsapp"
                    variant="outlined"
                    className="form-input"
                    style={{
                      borderBottomRightRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                    required
                    type="text"
                    placeholder="+225 0701031589"
                    name="numero"
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    id="outlined-basic4"
                    color="error"
                    label="Email"
                    variant="outlined"
                    className="form-input"
                    style={{
                      borderBottomRightRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                    required
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    id="outlined-basic4"
                    color="error"
                    label="En ligne/En presentiel"
                    variant="outlined"
                    className="form-input"
                    style={{
                      borderBottomRightRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                    required
                    type="text"
                    placeholder="lieu"
                    name="lieu"
                    value={type}
                    onChange={(e) => setLieu(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid md={8} style={{ marginTop: "25px", marginBottom: "15vh" }}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Message"
                  multiline
                  maxRows={5}
                  placeholder="Votre message"
                  color="error"
                  variant="standard"
                  style={{
                    height: "10vh",
                    width: "16rem",
                    outLine: "none",
                    marginLeft: "-15px",
                  }}
                  aria-label="With textarea"
                  required
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Grid>

              <Grid className="grid_bouton">
                <Button className="bouton_soumettre" type="submit">
                  envoyer
                </Button>
              </Grid>
            </div>
          </Form>
        </div>

        <div className="infosEntreprise">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                width: "2rem",
                border: "1px solid transparent",
                marginRight: "25px",
              }}
            >
              <Card.Body>
                <img src={iconeLoca} alt="Icone de localisation" />
              </Card.Body>
            </Card>
            <Card style={{ width: "18rem", border: "1px solid transparent" }}>
              <Card.Body >
                <h3 className="loc">Nous trouver:</h3>
                <p>Abidjan, Cocody Angré 7ème Tranche</p>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                width: "2rem",
                border: "1px solid transparent",
                marginRight: "25px",
              }}
            >
              <Card.Body>
                <img src={iconeEmail} alt="Icone de Email" />
              </Card.Body>
            </Card>
            <Card style={{ width: "18rem", border: "1px solid transparent" }}>
              <Card.Body>
                <h3 className="loc">Adresse email:</h3>
                <p className="mail-contact">
                  {" "}
                  contact@africantrainingcenterconsulting.com{" "}
                </p>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                width: "2rem",
                border: "1px solid transparent",
                marginRight: "25px",
              }}
            >
              <Card.Body>
                <img src={iconeTel} alt="Icone de Telephone" />
              </Card.Body>
            </Card>
            <Card style={{ width: "18rem", border: "1px solid transparent" }}>
              <Card.Body>
                <h3 className="loc">Nous appeler :</h3>
                <p style={{ margin: "0", padding: "0" }}>+225 07 04 85 24 43</p>
                <p style={{ margin: "0", padding: "0" }}>+225 07 77 82 18 51</p>
                <p style={{ margin: "0", padding: "0" }}>+225 07 67 45 55 76</p>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
