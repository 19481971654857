
import React from "react";
import Button from "react-bootstrap/Button";
import "../../styles/entreprises.css";
import persoInvestissement from "../../images/persoInvestissement.jpg";
import pmpImage from "../../images/pmp-2.png";
import { Link } from "react-router-dom";
import img from "../../images/Autres/pmp.jpg";


const Particulier = () => {
  return (
    <>
      <div className="banner-container">
        <img src={img} alt="Formation certifiante" className="banner-image" />
        <div className="banner-title">
          <p className="text-banner">Formation certifiante</p>
        </div>
      </div>

      <h2 className="h2_centre">PMP® Project Management Professional</h2>

      <div className="entreprise_bloc2">
        <div className="img_illust">
          <img src={pmpImage} alt="imageservice1"></img>
        </div>
        <div className="contenu_service">
          <h3>Ce que vous devez savoir: </h3>
          <p>
            Une certification internationale Américaine à grande valeur ajoutée
            en gestion de projet, 4ème dans le classement des plus prestigieuses
            certifications dans le monde, la certification PMP confère à son
            détenteur une reconnaissance internationale de sa maîtrise en
            gestion de projet. Le PMP® est une certification décernée par le PMI
            (Project Management Institute), fondée en 1969, une association
            professionnelle à but non lucratif qui propose des méthodes de
            Gestion de projet. Son siège est à Philadelphie en Pennsylvanie
            (États-Unis), elle compte d'après son site internet plus de 700 000
            membres répartis dans plus de 200 pays (chiffres 2022). Elle élabore
            et publie des standards relatifs à la gestion de projet et propose
            différentes certifications dans ce domaine.
          </p>
          <p>N.B : Guide PMBOK 6è & 7è Edition offerts en version numérique</p>
        </div>
      </div>


            <div className='entreprise_bloc2'>

                 <div className='contenu_service'>
                    <h3>Pourquoi vous devez faire cette formation</h3>
                    <p>
              L'alignement à des standards internationaux et l'opportunité d'améliorer son savoir-faire en gestion
               de projet. Le PMP® vous ouvre des portes dans toutes les industries et vous connecte à une communauté
                d’organisations et d’experts émérites dans le monde entier.
              Est éligible, toute personne possédante au moins 3 années d'expériences professionnelles
              de préférence dans les projets avec un diplôme universitaire.
                    </p>
            <p className='prix-formation'>
              Prix de la formation (HT): 950.000 F CFA
            </p>
            <p className='frais-examen'>Frais d’examen (HT) : 350.000 F CFA</p>
            <a className="button" href="/contact">Contactez-nous</a>     </div>
            </div>



        </>


    );
}


export default Particulier;
