import img1 from "../images/Consulting/amoa-consulting.jpg";
import img2 from "../images/Consulting/audit.jpg";
import img3 from "../images/Consulting/roadmap.jpg";
import img4 from "../images/Consulting/gestionProjet.jpg";
import img5 from "../images/Consulting/cartographie.jpg";
import img6 from "../images/Consulting/pmoSoutien.jpg";
import img7 from "../images/Consulting/pmoSoutien.jpg";

export const ConsultingServices = [
  {
    image: img1,
    title: "Assistance Maître ouvrage de Projet (AMOA)",
    description:
      "Cette assistance en gestion de projet de ATCC consiste à aider à l’élaboration du Kick off de lancement. L’élaboration d’un plan de management de projet, assiste le maître d’ouvrage dans l’élaboration de ces documents de projet. L’AMOA participe aux réunions de projet en y apportant son appui en cas de besoin. L’AMOA assure le reporting du projet en donnant mensuellement l’état d’avancement du projet. Des recommandations et des conseils en cas de besoin aux différents prestataires.",
    LivrablesAttendus:
      "Kick off de Lancement Charte de projet, Registre des parties prenantes du projet,Plan de gestion des communications,Cartographie des risquesEnoncé du contenu du projet, Structure de découpage du projet, Etablissement des critères d’acceptation, Etablissement des taux de réalisation,Analyses des processus, Peut participer aux Tests ou aux inspections en cas de besoin",
    DuréeContrat: "6 mois à un an renouvelable selon la durée du projet",
    coût: "2 millions de francs / mois",
    RessourcesAllouées: "2 experts Séniors",
  },
  {
    image: img2,
    title: "Audit interne ou externe de projet",
    description:
      "Analyse SWOT, Analyse des processus, Analyse de la gouvernance de projet, Détection des obstacles, Analyse WHY WHY,Analyse des documents projets, Etats du projet,Recommandations  ",
    LivrablesAttendus: "Résultat de l’audit et recommandations",
    DuréeContrat: "40 H – 5 JOURS",
    coût: "500000 frs/ jour",
    RessourcesAllouées: "1 Expert Senior",
  },
  {
    image: img3,
    title:
      "Project ROADMAP ou feuille de route pour les projets AGILES ou hybrides",
    description:
      "La difficulté dans les projets Agiles ou hybrides est de définir une feuille de route permettant de pouvoir coordonner le projet, organiser les différentes étapes tout en restant transparent. ATCC vous aidera à définir une feuille de route de votre projet, organiser des points de collecte des feedbacks clients, ainsi que de donner la prédominance aux tests fonctionnels et non fonctionnels.",
    LivrablesAttendus:
      "PROJECT ROADMAP, Planification des réunions clients prestataires, Établissement des DEFINITION OF DONE (DoD) des livrables",
    DuréeContrat: "40 H – 5 JOURS",
    coût: "500000 frs/ jour",
    RessourcesAllouées: "1 Expert Senior",
  },
  {
    image: img4,
    title: "ELABORATION D’UNE POLITIQUE DE GESTION DE PROJET",
    description:
      "ATCC s’engage à rédiger une politique de gestion de projet pour votre organisation qui s’adaptera à votre réalité tout en respectant la norme PMI en matière de gestion de projet. De plus en plus, l’agilité s’introduit dans nos projets d’où l’importance d’intégrer l’agilité dans votre politique projet ou de mettre votre politique Projet à jour.",
    LivrablesAttendus:
      "Analyse SWOT des procédures et des mises à jour, Rédaction de la politique de gestion de projet",
    DuréeContrat: "30 Jours",
    coût: "Senior: 500000 frs/jour, Junior: 300000 Ffca/Jour ",
    RessourcesAllouées: "1 Expert Senior ou 1 Expert Junior",
  },
  {
    image: img5,
    title: "ANALYSE DES RISQUES ET CARTOGRAPHIE DES RISQUES",
    description:
      "Analyse des documents,Analyse SWOT de l’organisation,Interview, Analyse des processus et procédures,Rapport des risques, Cartographie des risques    ",
    LivrablesAttendus: "Rapports des risques, Cartographie sur les risques ",
    DuréeContrat: "80 H – 10 JOURS",
    coût: "Senior: 500000 frs/jour, Junior: 300000 Ffca/Jour ",
    RessourcesAllouées: "1 Expert Senior ou 1 Expert Junior",
  },
  {
    image: img6,
    title: "ASSISTANCE POUR LA MISE EN PLACE D’UN PMO DE SOUTIEN",
    description:
      "Le bureau de projet de soutien joue plus un rôle consultatif en fournissant des modèles, des formations et des conseils. Notre assistance dans sa mise en place consistera :Dans la fourniture des modèles appropriés, L’obtention du BUY IN des différents chefs de projet, La formation des membres du PMO dans leurs différents rôles, La définition des missions du bureau de projet  ",
    LivrablesAttendus:
      "Fournitures des modèles et divers documents de projet, Un plan de communication PMO – chef de projet - Direction, Élaboration des procédures d’un PMO de soutien, Tests de fonctionnalité des différents services du PMO, Fixation du timing entre les demandes et réponses du PMO, Mesure de satisfaction des utilisateurs du PMO ",
    DuréeContrat: "80 H – 10 JOURS",
    coût: "Senior: 500000 frs/jour, Junior: 300000 Ffca/Jour ",
    RessourcesAllouées: "1 Expert Senior ou 1 Expert Junior",
  },
  {
    image: img7,
    title: "ASSISTANCE POUR LA MISE EN PLACE D’UN PMO DE CONFORMITE",
    description:
      "Le bureau de projet de conformité veille aux respects des politiques projets en plus du rôle de soutien qu’il doit avoir. Notre assistance dans sa mise en place consistera : Dans la fourniture des modèles appropriés, L’obtention du BUY IN des différents chefs de projet, La formation des membres du PMO dans leurs différents rôles, La définition des missions du bureau de projet, Analyse SWOT des procédures existantes, Rédaction d’une politique de gestion de projet, Définition des points de contrôle    ",
    LivrablesAttendus:
      "Fournitures des modèles et divers documents de projet, n plan de communication PMO – chef de projet - Direction, Élaboration des procédures d’un PMO de soutien, Tests de fonctionnalité des différents services du PMO, Fixation du timing entre les demandes et réponses du PMO, Mesure de satisfaction des utilisateurs du PMO, Analyse SWOT, Rédaction d’une politique de gestion de projet, Définition des points de contrôle ",
    DuréeContrat: "2 mois ou 60 jours",
    coût: "Senior: 500000 frs/jour, Junior: 300000 Ffca/Jour ",
    RessourcesAllouées: "1 Expert Senior ou 1 Expert Junior",
  },
];
