import React from "react";
import '../styles/header.css'
import '../styles/footer.css'
import '../styles/contact.css'
import { slides } from '../Exports/Slides.js';
import { serviceDatas } from '../Exports/ServiceDatas.js';
import Services from './services/Services';
import Banner from "./Banner";
import Objectif from "../composants/Objectif";
import PartnersCarousel from "../composants/PartnersCarousel";
import About from "./About";
import Cibles from "./Cibles";
//import img from "../images/home-page.jpg"

const Acceuil = () => {

    return (
      <>
        < Banner />
        <Services serviceData={serviceDatas} />
        <Objectif />
        < About />
        < Cibles />
          <PartnersCarousel/>
      </>
    );
}
export default Acceuil

 //
  //< MySection />
  //
  //< Apropos />
